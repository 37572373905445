import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container } from "react-bootstrap"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404" />
    <Container className="text-center pb-6 pt-6">
      <h1>404</h1>
      <p>Acabas de llegar a una ruta que sí existe ... la tristeza.</p>
    </Container>
  </Layout>
)

export default NotFoundPage
